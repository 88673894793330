import Vue from "vue";
import VueRouter from "vue-router";
import HomeView from "../views/HomeView.vue";
import store from '@/store'
let originPush = VueRouter.prototype.push;
let originReplace = VueRouter.prototype.replace;
//重写VueRouter.prototype身上的push方法了
VueRouter.prototype.push = function(location, resolve, reject) {
  if (resolve && reject) {
   
    originPush.call(this, location, resolve, reject);
  } else {
    originPush.call(
      this,
      location,
      () => {},
      () => {}
    );
  }
};
//重写VueRouter.prototype身上replace的方法了
VueRouter.prototype.replace = function(location, resolve, reject) {
  if (resolve && reject) {
    originReplace.call(this, location, resolve, reject);
  } else {
    originReplace.call(
      this,
      location,
      () => {},
      () => {}
    );
  }
};
Vue.use(VueRouter);

const routes = [
  {
    path: "/login",
    name: "login",
    meta:{
      title:'Login - Co-logistics'
    },
    component: () => import("../views/login/Login.vue"),
  },
  {
    path: "/signUp",
    name: "signUp",
    meta:{
      title:'SignUp - Co-logistics'
    },
    component: () => import("../views/login/SignUp.vue"),
  },
  {
    path: "/signUpGoogle",
    name: "signUpGoogle",
    meta:{
      title:'SignUp With Google - Co-logistics'
    },
    component: () => import("../views/login/SignUpGoogle.vue"),
  },
  {
    path: "/verifyEmail",
    name: "verifyEmail",
    meta:{
      title:'Verify Email - Co-logistics'
    },
    component: () => import("../views/login/VerifyEmail.vue"),
  },
  {
    path: "/forgotPassword",
    name: "forgotPassword",
    meta:{
      title:'Forgot Password - Co-logistics'
    },
    component: () => import("../views/login/ForgotPassword.vue"),
  },
  {
    path: "/sendToEmail",
    name: "sendToEmail",
    meta:{
      title:'Send to Email - Co-logistics'
    },
    component: () => import("../views/login/SendToEmail.vue"),
  },
  {
    path: "/resetPassword",
    name: "resetPassword",
    meta:{
      title:'Reset Password - Co-logistics'
    },
    component: () => import("../views/login/ResetPassword.vue"),
  },
  {
    path: "/loginCode",
    name: "loginCode",
    meta:{
      title:'Cargosoon'
    },
    component: () => import("../views/login/loginCode.vue"),
  },
  {
    path: "/",
    component: HomeView,
    redirect: "/main/home",
    meta:{
      title:'Co-logistics',
    },
    children:[
      {
        path: "/main/home",
        name: "home",
        meta:{
          title:'Home - Co-logistics'
        },
        component: () => import("../views/home/Home.vue"),
      },
      {
        path: "/main/inventories",
        name: "inventories",
        meta:{
          title:'Inventories - Co-logistics'
        },
        component: () => import("../views/warehouse/Inventories.vue"),
      },
      {
        path: "/main/order",
        name: "order",
        meta:{
          title:'My Order - Co-logistics'
        },
        component: () => import("../views/order/Order.vue"),
      },
      {
        path: "/main/account",
        name: "account",
        meta:{
          title:'My Account - Co-logistics'
        },
        component: () => import("../views/account/Account.vue"),
      },
      {
        path: "/track/tracking",
        name: "tracking",
        meta:{
          title:'Tracking - Co-logistics'
        },
        component: () => import("../views/track/Tracking.vue"),
      },
      {
        path: "/track/trackingDetail",
        name: "trackingDetail",
        meta:{
          title:'Tracking Detail - Co-logistics'
        },
        component: () => import("../views/track/TrackDetails.vue"),
      },
      {
        path: "/account/wallet",
        name: "wallet",
        meta:{
          title:'My Wallet - Co-logistics'
        },
        component: () => import("../views/account/Wallet.vue"),
      },
      {
        path: "/account/wallet-topup",
        name: "walletTopup",
        meta:{
          title:'Wallet Topup - Co-logistics'
        },
        component: () => import("../views/account/WalletTopup.vue"),
      },
      {
        path: "/account/TopUpResult",
        name: "TopUpResult",
        meta:{
          title:'Top Up Result - Co-logistics'
        },
        component: () => import("../views/account/TopUpResult.vue"),
      },
      {
        path: "/account/walletDetail",
        name: "walletDetail",
        meta:{
          title:'My Wallet - Co-logistics'
        },
        component: () => import("../views/account/WalletDetail.vue"),
      },
      {
        path: "/account/member",
        name: "member",
        meta:{
          title:'My Member - Co-logistics'
        },
        component: () => import("../views/account/Member.vue"),
      },
      {
        path: "/account/memberReview",
        name: "memberReview",
        meta:{
          title:'Member Review - Co-logistics'
        },
        component: () => import("../views/account/MemberReview.vue"),
      },
      {
        path: "/account/notice",
        name: "notice",
        meta:{
          title:'Notice - Co-logistics'
        },
        component: () => import("../views/account/Notice.vue"),
      },
      {
        path: "/account/setting",
        name: "setting",
        meta:{
          title:'Setting - Co-logistics'
        },
        component: () => import("../views/account/Setting.vue"),
      },
      {
        path: "/account/profile",
        name: "profile",
        meta:{
          title:'Profile - Co-logistics'
        },
        component: () => import("../views/account/Profile.vue"),
      },
      {
        path: "/account/changePassword",
        name: "changePassword",
        meta:{
          title:'ChangePassword - Co-logistics'
        },
        component: () => import("../views/account/ChangePassword.vue"),
      },
      {
        path: "/account/address",
        name: "address",
        meta:{
          title:'Address - Co-logistics'
        },
        component: () => import("../views/account/Address.vue"),
      },
      {
        path: "/account/addAddress",
        name: "addAddress",
        meta:{
          title:'Add Address - Co-logistics'
        },
        component: () => import("../views/account/AddAddress.vue"),
      },
      {
        path: "/warehouse/ApplySpace",
        name: "ApplySpace",
        meta:{
          title:'Apply For Storage Space - Co-logistics'
        },
        component: () => import("../views/warehouse/ApplySpace.vue"),
      },
      {
        path: "/warehouse/ApplySpaces",
        name: "ApplySpaces",
        meta:{
          title:'Apply For Storage Space - Co-logistics'
        },
        component: () => import("../views/warehouse/ApplySpaces.vue"),
      },
      {
        path: "/warehouse/RentCalculation",
        name: "RentCalculation",
        meta:{
          title:'Warehouse Rent Calculation - Co-logistics'
        },
        component: () => import("../views/warehouse/Rent.vue"),
      },
      {
        path: "/warehouse/InboundManagement",
        name: "InboundManagement",
        meta:{
          title:'Inbound Management - Co-logistics'
        },
        component: () => import("../views/warehouse/InboundManagement.vue"),
      },
      {
        path: "/warehouse/OutboundManagement",
        name: "OutboundManagement",
        meta:{
          title:'Outbound Management - Co-logistics'
        },
        component: () => import("../views/warehouse/OutboundManagement.vue"),
      },
      {
        path: "/warehouse/OutboundDetail",
        name: "OutboundDetail",
        meta:{
          title:'Outbound Management Detail - Co-logistics'
        },
        component: () => import("../views/warehouse/OutboundDetail.vue"),
      },
      {
        path: "/warehouse/StockList",
        name: "StockList",
        meta:{
          title:'Stock List - Co-logistics'
        },
        component: () => import("../views/warehouse/StockList.vue"),
      },
      {
        path: "/main/bookingSearch",
        name: "bookingSearch",
        meta:{
          title:'Booking Search - Co-logistics'
        },
        component: () => import("../views/home/bookResult.vue"),
      },
      {
        path: "/main/portSearch",
        name: "portSearch",
        meta:{
          title:'Port to Port - Co-logistics'
        },
        component: () => import("../views/home/portResult.vue"),
      },
      {
        path: "/main/trackingSearch",
        name: "trackingSearch",
        meta:{
          title:'Tracking Search - Co-logistics'
        },
        component: () => import("../views/home/Search.vue"),
      },
      {
        path: "/order/booking",
        name: "reqOrder",
        meta:{
          title:'Request Order - Co-logistics'
        },
        component: () => import("../views/order/ReqOrder.vue"),
      },
      {
        path: "/order/forwardingOrder/:id",
        name: "forwardingOrder",
        meta:{
          title:'Forwarding Order - Co-logistics'
        },
        component: () => import("../views/order/ForwardingOrder.vue"),
      },
    ]
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to,from,next)=>{
  if (to.meta.title) {
    document.title = to.meta.title
  }
  next()
});
router.beforeEach((to,from,next)=>{
  let token = store.state.user.token
  // console.log(token);
  if(token){
    let ps = JSON.parse(localStorage.getItem('userInfo')).price_status
    if(ps == '1'){
      next()
    }else{
      if(to.path=='/main/bookingSearch' || to.path=='/main/portSearch' || to.path=='/order/booking'){
        next({path:'/main/home'})
      }else{
        next()
      }
    }
  }else{
    if(to.query.ref){
      localStorage.setItem("invitation_code", to.query.ref)
    }

    if(to.path.indexOf('/account')!=-1||to.path.indexOf('/main')!=-1||to.path.indexOf('/order')!=-1
    ||to.path.indexOf('/warehouse')!=-1){
      if(to.path.indexOf('/main/home')!=-1 || to.path.indexOf('/main/account')!=-1){
        next()
      }else{
        next({path:'/login'})
      }
    }else{
      next()
    }
  }
})
export default router;
