
import axios from "axios";
import store from '@/store';
import router from '@/router/index';
let requests = axios.create({
  baseURL: "https://m.co-logistics.com/api",
  // baseURL: "http://testapp.co-logistics.com/api",
  // baseURL: "http://192.168.0.77:9001",
  timeout:25000,
});

requests.interceptors.request.use((config) => {
  //需要携带token带给服务器
  if(store.state.user.token){
    config.headers.token = store.state.user.token;
    // config.headers.token = 'MTcwMDYzNDEzNF8xMjIyX2FhODNkMTA2MTY2MDY3M2NiOWI1OGRlMTYwZjRkOWNlZmFkN2FiMDVfOTc5N19kYXZlLmhlQGNvLWxvZ2lzdGljcy5jb20='
    // config.headers.token = 'MTcwMDQ0MzU0Ml8xMjIyX2FhODNkMTA2MTY2MDY3M2NiOWI1OGRlMTYwZjRkOWNlZmFkN2FiMDVfNjA1M19kYXZlLmhlQGNvLWxvZ2lzdGljcy5jb20='
  }
  return config;
});

requests.interceptors.response.use(
  (res) => {
    // if(res.data.msg=='请先登录'){
    //   store.dispatch('userLogout')
    //   router.push('/login')
    // }
    if(res.data.msg=='token失效' || res.data.msg=='账号异常'){
      store.dispatch('userLogout')
      router.push('/login')
    }
    return res.data;
  },
  (err) => {
   
  }
);
export default requests;
