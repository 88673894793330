<template>
  <div style="position: relative;width: 100vw;height: 100vh;">
    <div v-show="chatPanel" class="fixed w-screen h-screen z-100 bg-bg">
      <div class="pt-3 sticky top-0 text-center bg-white shadow-sm z-10">
        <div @click="ChatPanelOpen" class="absolute left-2.5 top-2.5">
          <svg t="1705290159922" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="5020" width="28" height="28"><path d="M675.11798 267.269064L430.406848 511.992078l244.710142 244.723015a38.453744 38.453744 0 0 1-54.380252 54.383222l-271.900267-271.91116a38.455724 38.455724 0 0 1 0-54.382232l271.900267-271.91116a38.453744 38.453744 0 0 1 54.381242 54.375301z" fill="#000000" p-id="5021"></path></svg>
        </div>
        <div class="pb-3">Customer service</div>
      </div>
      <div ref="chatPanels" class="h-full overflow-auto mx-4 pb-40">
        <div v-for="(i,n) in chatMessage" :key="n">
          <div v-if="i.is_me == 1" class="py-2 flex justify-end">
            <div class="pr-2 flex flex-col items-end" style="width:calc( 100% - 80px )">
              <div class="flex items-end justify-end flex-wrap">
                <div class="text-xs text-gray-500">{{i.date_entered}}</div>
                <div class="ml-2 text-sm dark:text-gray-400">{{i.nickname}}</div>
              </div>
              <div v-if="i.msg_type == 0" class="mt-1.5 px-3 py-1.5 text-13 text-white rounded-3xl bg-chatMe min-w-40px max-w-full relative break-words">
                {{i.msg}}
                <div v-show="i.is_read == 1" class="absolute bottom-0 -left-6">
                  <svg t="1678437391950" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="30218" width="18" height="18"><path d="M511.006881 64.592111c-247.195985 0-447.565479 200.369493-447.565479 447.566502 0 247.168356 200.369493 447.566502 447.565479 447.566502 247.168356 0 447.566502-200.398146 447.566502-447.566502C958.573383 264.961604 758.175237 64.592111 511.006881 64.592111zM511.006881 895.376551c-211.645304 0-383.217938-171.59924-383.217938-383.217938 0-211.646328 171.572634-383.217938 383.217938-383.217938s383.217938 171.572634 383.217938 383.217938C894.22482 723.776288 722.652186 895.376551 511.006881 895.376551z" fill="#7a7a7a" p-id="30219"></path><path d="M706.369338 385.719922c-11.580756 0-22.099321 4.700045-29.706579 12.308327L454.6135 620.581997l-137.570215-138.074705c-7.608282-7.609305-18.098193-12.308327-29.706579-12.308327-23.161512 0-41.958624 18.769482-41.958624 41.959647 0 11.580756 4.670369 22.043039 12.252045 29.622668l167.277818 167.837566c7.580652 7.580652 18.098193 12.308327 29.706579 12.308327 11.609409 0 22.099321-4.700045 29.707602-12.308327l0 0.028653 251.754814-252.343215c7.553023-7.580652 12.252045-18.070564 12.252045-29.65132C748.327962 404.491451 729.53085 385.719922 706.369338 385.719922z" fill="#7a7a7a" p-id="30220"></path></svg>
                </div>
              </div>
              <div v-if="i.msg_type == 2" class="mt-1.5 px-3 py-1.5 text-13 rounded-3xl bg-chatMe min-w-40px max-w-full relative break-words text-blue-700">
                <a :href="i.msg" target="_blank" class="cursor-pointer underline">{{i.file_name}}</a>
                <div v-show="i.is_read == 1" class="absolute bottom-0 -left-6">
                  <svg t="1678437391950" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="30218" width="18" height="18"><path d="M511.006881 64.592111c-247.195985 0-447.565479 200.369493-447.565479 447.566502 0 247.168356 200.369493 447.566502 447.565479 447.566502 247.168356 0 447.566502-200.398146 447.566502-447.566502C958.573383 264.961604 758.175237 64.592111 511.006881 64.592111zM511.006881 895.376551c-211.645304 0-383.217938-171.59924-383.217938-383.217938 0-211.646328 171.572634-383.217938 383.217938-383.217938s383.217938 171.572634 383.217938 383.217938C894.22482 723.776288 722.652186 895.376551 511.006881 895.376551z" fill="#7a7a7a" p-id="30219"></path><path d="M706.369338 385.719922c-11.580756 0-22.099321 4.700045-29.706579 12.308327L454.6135 620.581997l-137.570215-138.074705c-7.608282-7.609305-18.098193-12.308327-29.706579-12.308327-23.161512 0-41.958624 18.769482-41.958624 41.959647 0 11.580756 4.670369 22.043039 12.252045 29.622668l167.277818 167.837566c7.580652 7.580652 18.098193 12.308327 29.706579 12.308327 11.609409 0 22.099321-4.700045 29.707602-12.308327l0 0.028653 251.754814-252.343215c7.553023-7.580652 12.252045-18.070564 12.252045-29.65132C748.327962 404.491451 729.53085 385.719922 706.369338 385.719922z" fill="#7a7a7a" p-id="30220"></path></svg>
                </div>
              </div>
              <div v-if="i.msg_type == 1" class="mt-1.5 relative">
                <!-- <div @click="openImg($event)" v-html="i.msg" class="messageImg">{{i.msg}}</div> -->
                <div @click="openImg($event)" class="messageImg">
                  <img :src="i.msg" alt="">
                </div>
                <div v-show="i.is_read == 1" class="absolute bottom-0 -left-6">
                  <svg t="1678437391950" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="30218" width="18" height="18"><path d="M511.006881 64.592111c-247.195985 0-447.565479 200.369493-447.565479 447.566502 0 247.168356 200.369493 447.566502 447.565479 447.566502 247.168356 0 447.566502-200.398146 447.566502-447.566502C958.573383 264.961604 758.175237 64.592111 511.006881 64.592111zM511.006881 895.376551c-211.645304 0-383.217938-171.59924-383.217938-383.217938 0-211.646328 171.572634-383.217938 383.217938-383.217938s383.217938 171.572634 383.217938 383.217938C894.22482 723.776288 722.652186 895.376551 511.006881 895.376551z" fill="#7a7a7a" p-id="30219"></path><path d="M706.369338 385.719922c-11.580756 0-22.099321 4.700045-29.706579 12.308327L454.6135 620.581997l-137.570215-138.074705c-7.608282-7.609305-18.098193-12.308327-29.706579-12.308327-23.161512 0-41.958624 18.769482-41.958624 41.959647 0 11.580756 4.670369 22.043039 12.252045 29.622668l167.277818 167.837566c7.580652 7.580652 18.098193 12.308327 29.706579 12.308327 11.609409 0 22.099321-4.700045 29.707602-12.308327l0 0.028653 251.754814-252.343215c7.553023-7.580652 12.252045-18.070564 12.252045-29.65132C748.327962 404.491451 729.53085 385.719922 706.369338 385.719922z" fill="#7a7a7a" p-id="30220"></path></svg>
                </div>
              </div>
              <div v-if="i.msg_type == 3 || i.msg_type == 4" class="mt-1.5 relative">
                <div class="p-3 rounded-md bg-white shadow-default">
                  <div class="flex items-center mb-1.5">
                    <img v-show="i.msg.img != ''" :src="i.msg.img" class="w-7 h-7 rounded-full mr-1" alt="">
                    <div class="text-sm font-semibold">{{i.msg.name}}</div>
                  </div>
                  <div v-show="i.msg.date != ''" class="flex items-center">
                    <img src="../assets/dtdtime.svg" class="w-3 h-3 mr-1" alt="">
                    <div class="text-xs">{{i.msg.date}}</div>
                  </div>
                  <div class="flex items-center my-0.5">
                    <img src="../assets/dtdprice.svg" class="w-3 h-3 mr-1" alt="">
                    <div class="flex items-center">
                      <div class="text-red-500 font-semibold">{{i.msg.price}}</div>
                      <div v-show="i.msg_type == 3" class="text-xs">{{i.msg.weight}}</div>
                    </div>
                  </div>
                  <div v-show="i.msg.destination != ''" class="flex items-center">
                    <img src="../assets/dtdlocation.svg" class="w-3 h-3 mr-1" alt="">
                    <div class="text-xs">{{i.msg.destination}}</div>
                  </div>
                </div>
                <div v-show="i.is_read == 1" class="absolute bottom-0 -left-6">
                  <svg t="1678437391950" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="30218" width="18" height="18"><path d="M511.006881 64.592111c-247.195985 0-447.565479 200.369493-447.565479 447.566502 0 247.168356 200.369493 447.566502 447.565479 447.566502 247.168356 0 447.566502-200.398146 447.566502-447.566502C958.573383 264.961604 758.175237 64.592111 511.006881 64.592111zM511.006881 895.376551c-211.645304 0-383.217938-171.59924-383.217938-383.217938 0-211.646328 171.572634-383.217938 383.217938-383.217938s383.217938 171.572634 383.217938 383.217938C894.22482 723.776288 722.652186 895.376551 511.006881 895.376551z" fill="#7a7a7a" p-id="30219"></path><path d="M706.369338 385.719922c-11.580756 0-22.099321 4.700045-29.706579 12.308327L454.6135 620.581997l-137.570215-138.074705c-7.608282-7.609305-18.098193-12.308327-29.706579-12.308327-23.161512 0-41.958624 18.769482-41.958624 41.959647 0 11.580756 4.670369 22.043039 12.252045 29.622668l167.277818 167.837566c7.580652 7.580652 18.098193 12.308327 29.706579 12.308327 11.609409 0 22.099321-4.700045 29.707602-12.308327l0 0.028653 251.754814-252.343215c7.553023-7.580652 12.252045-18.070564 12.252045-29.65132C748.327962 404.491451 729.53085 385.719922 706.369338 385.719922z" fill="#7a7a7a" p-id="30220"></path></svg>
                </div>
              </div>
            </div>
            <img :src="i.head_img" class="flex-shrink-0 w-12 h-12 rounded-full" alt="">
          </div>

          <div v-else class="py-2 flex">
            <img :src="i.head_img" class="flex-shrink-0 w-12 h-12 rounded-full" alt="">
            <div class="pl-2 flex flex-col items-start" style="width:calc( 100% - 80px )">
              <div class="flex items-start flex-wrap-reverse">
                <div class="text-sm mr-2 dark:text-gray-400">{{i.nickname}}</div>
                <div class="text-xs text-gray-500">{{i.date_entered}}</div>
              </div>
              <div class="mt-1.5 px-3 py-1.5 text-13 rounded-3xl bg-gray-200 min-w-40px max-w-full overflow-x-hidden break-words"
                v-if="i.msg_type == 0">{{i.msg}}</div>
              <div class="mt-1.5 px-3 py-1.5 text-13 rounded-3xl bg-gray-200 min-w-40px max-w-full overflow-x-hidden break-words text-blue-700"
                v-if="i.msg_type == 2">
                <a :href="i.msg" download class="cursor-pointer underline">{{i.file_name}}</a>
              </div>
              <!-- <div @click="openImg($event)" v-if="i.msg_type == 1" v-html="i.msg" class="messageImg mt-1.5">{{i.msg}}</div> -->
              <div @click="openImg($event)" v-if="i.msg_type == 1" class="messageImg mt-1.5">
                <img :src="i.msg" alt="">
              </div>
              <div class="mt-1.5 px-3 py-1.5 text-13 rounded-3xl bg-gray-200 min-w-40px max-w-full overflow-x-hidden break-words"
                v-if="i.msg_type == 5">
                <div>{{nologinHello[0]}}</div>
                <div>{{nologinHello[1]}}</div>
                <div>{{nologinHello[2]}}</div>
                <div>{{nologinHello[3]}}</div>
                <div class="mt-2 pt-2 border-t border-gray-700">WhatsApp:{{manageNew.whatsappp}}</div>
                <div>e-mail:{{manageNew.service_email}}</div>
              </div>
            </div>
          </div>
        </div>

        <div v-show="bookingChat"
          class="mt-4 p-3 flex justify-between items-center rounded-md border border-orange relative">
          <svg t="1678422103952" @click="bookingChat = false" class="absolute right-0 -top-3.5 icon cursor-pointer" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="2580" width="26" height="26"><path d="M512 128C300.8 128 128 300.8 128 512s172.8 384 384 384 384-172.8 384-384S723.2 128 512 128zM512 832c-179.2 0-320-140.8-320-320s140.8-320 320-320 320 140.8 320 320S691.2 832 512 832z" p-id="2581" fill="#7a7a7a"></path><path d="M672 352c-12.8-12.8-32-12.8-44.8 0L512 467.2 396.8 352C384 339.2 364.8 339.2 352 352S339.2 384 352 396.8L467.2 512 352 627.2c-12.8 12.8-12.8 32 0 44.8s32 12.8 44.8 0L512 556.8l115.2 115.2c12.8 12.8 32 12.8 44.8 0s12.8-32 0-44.8L556.8 512l115.2-115.2C684.8 384 684.8 364.8 672 352z" p-id="2582" fill="#7a7a7a"></path></svg>
          <div class="overflow-ellipsis overflow-hidden whitespace-nowrap text-xs dark:text-gray-400">Do you want to consult {{bookingName}}?</div>
          <button @click="sendBooking" class="ml-3 px-5 py-1.5 bg-orange hover:bg-orange1 text-white rounded-md text-xs">Send</button>
        </div>
        <div v-show="portChat"
          class="mt-4 p-3 flex justify-between items-center rounded-md border border-orange relative">
          <svg t="1678422103952" @click="portChat = false" class="absolute right-0 -top-3.5 icon cursor-pointer" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="2580" width="26" height="26"><path d="M512 128C300.8 128 128 300.8 128 512s172.8 384 384 384 384-172.8 384-384S723.2 128 512 128zM512 832c-179.2 0-320-140.8-320-320s140.8-320 320-320 320 140.8 320 320S691.2 832 512 832z" p-id="2581" fill="#7a7a7a"></path><path d="M672 352c-12.8-12.8-32-12.8-44.8 0L512 467.2 396.8 352C384 339.2 364.8 339.2 352 352S339.2 384 352 396.8L467.2 512 352 627.2c-12.8 12.8-12.8 32 0 44.8s32 12.8 44.8 0L512 556.8l115.2 115.2c12.8 12.8 32 12.8 44.8 0s12.8-32 0-44.8L556.8 512l115.2-115.2C684.8 384 684.8 364.8 672 352z" p-id="2582" fill="#7a7a7a"></path></svg>
          <div class="overflow-ellipsis overflow-hidden whitespace-nowrap text-xs dark:text-gray-400">Do you want to inquire the price of {{portName}}?</div>
          <button @click="sendPort" class="ml-3 px-5 py-1.5 bg-orange hover:bg-orange1 text-white rounded-md text-xs">Send</button>
        </div>
        <div v-show="orderChat"
          class="mt-4 p-3 flex justify-between items-center rounded-md border border-orange relative">
          <svg t="1678422103952" @click="orderChat = false" class="absolute right-0 -top-3.5 icon cursor-pointer" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="2580" width="26" height="26"><path d="M512 128C300.8 128 128 300.8 128 512s172.8 384 384 384 384-172.8 384-384S723.2 128 512 128zM512 832c-179.2 0-320-140.8-320-320s140.8-320 320-320 320 140.8 320 320S691.2 832 512 832z" p-id="2581" fill="#7a7a7a"></path><path d="M672 352c-12.8-12.8-32-12.8-44.8 0L512 467.2 396.8 352C384 339.2 364.8 339.2 352 352S339.2 384 352 396.8L467.2 512 352 627.2c-12.8 12.8-12.8 32 0 44.8s32 12.8 44.8 0L512 556.8l115.2 115.2c12.8 12.8 32 12.8 44.8 0s12.8-32 0-44.8L556.8 512l115.2-115.2C684.8 384 684.8 364.8 672 352z" p-id="2582" fill="#7a7a7a"></path></svg>
          <div class="text-xs dark:text-gray-400">You may want to inquire about order number {{orderName}}?</div>
          <button @click="sendOrder" class="ml-3 px-5 py-1.5 bg-orange hover:bg-orange1 text-white rounded-md text-xs">Send</button>
        </div>
      </div>
      <div class="fixed w-full p-3 bottom-0 bg-white flex items-center">
        <div v-show="entering" class="absolute -top-5 left-2 text-xs text-gray-500">
          The other is typing...</div>
        <div class="flex-shrink-0 flex mr-3">
          <div ref="emojiPanel" class="relative">
            <img @click="emojiPanel = !emojiPanel" src="../assets/emoji.svg" class="w-7 h-7 p-1 rounded hover:bg-gray-200 dark:hover:bg-darkLine cursor-pointer" alt="">
            <div v-show="emojiPanel" class="absolute -top-28 -left-2 w-52 p-2 bg-white dark:bg-darkMain rounded-md shadow-md grid grid-cols-6 gap-1">
              <div v-for="(item,index) in emojiList" :key="index"
                @click="emojiChoose" class="emojiItem">{{ item }}</div>
            </div>
          </div>
          <input type="file"
            style="display: none;"
            accept="image/jpeg,image/png,image/jpg"
            id="files"
            ref="iptFileRef"
            @change="uploads" />
          <img @click="upload" src="../assets/chatUpload.svg"
            class="ml-2 w-7 h-7 p-1 rounded hover:bg-gray-200 dark:hover:bg-darkLine cursor-pointer" alt="">
        </div>
        <div class="w-full">
          <input ref="textArea" @blur="cancelEnter" @input="sendEnter"
            @keyup.enter.exact="sendMessage" @keyup.ctrl.enter="listenKey()"
            type="text" class="input text-xs" v-model="msg">
        </div>
        <div class="flex-shrink-0 ml-2.5">
          <button @click="sendMessage" class="rounded py-2 px-3 bg-blue-500 text-13 text-white">Send</button>
        </div>
      </div>
    </div>
    <div ref="mainView" class="mainPanel bg-bg">
      <router-view @menu="chooseMenu" @ischat="isChat"
        @isread="isRead" :noread="noRead" @readall="readAll"
        @bookingchat="bookingInfo" @portchat="portInfo" @orderchat="orderInfo"
        @accountM="ChatPanelOpen" :priceStatus="price_status"/>

      <div>
        <div @click="ChatPanelOpen" v-show="!chatShow"
          class="absolute bottom-32 right-1.5 p-1.5 bg-white hover:bg-gray-100
            rounded-full shadow-xxx flex cursor-pointer z-10">
          <div class="relative">
            <img :src="manage.image_url" class="w-9 h-9 rounded-full" alt="">
            <div class="absolute right-0 bottom-0 w-2.5 h-2.5 bg-green-400 rounded-full"></div>
          </div>
          <div v-show="chatNum>0" 
            class="absolute -top-1 py-1 px-1.5 bg-red-500 text-white rounded-full text-13 leading-3">{{chatNum}}</div>
        </div>
      </div>
    </div>
    <div v-show="isvisited != '0'" class="tabbar shadow-default">
      <div class="w-1/4">
        <router-link :to="{ name: 'home' }" class="barItem">
            <img v-show="isvisited != '1'" src="../assets/home.png" style="width: 24px;height: 24px;" alt="">
            <img v-show="isvisited == '1'" src="../assets/home-fill.png" style="width: 24px;height: 24px;" alt="">
            <div :class="[isvisited == '1' ? 'text-orange' : 'text-black']">Home</div>
        </router-link>
      </div>
      <div class="w-1/4">
        <router-link :to="{ name: 'inventories' }" class="barItem">
          <img v-show="isvisited != '2'" src="../assets/inventories.png" style="width: 22px;height: 24px;" alt="">
          <img v-show="isvisited == '2'" src="../assets/inventories-fill.png" style="width: 22px;height: 24px;" alt="">
          <div :class="[isvisited == '2' ? 'text-orange' : 'text-black']">Inventories</div>
        </router-link>
      </div>
      <div class="w-1/4">
        <router-link :to="{ name: 'order' }" class="barItem">
          <img v-show="isvisited != '3'" src="../assets/order.png" style="width: 24px;height: 24px;" alt="">
          <img v-show="isvisited == '3'" src="../assets/order-fill.png" style="width: 24px;height: 24px;" alt="">
          <div :class="[isvisited == '3' ? 'text-orange' : 'text-black']">My Order</div>
        </router-link>
      </div>
      <div class="w-1/4">
        <router-link :to="{ name: 'account' }" class="barItem">
          <img v-show="isvisited != '4'" src="../assets/account.png" style="width: 24px;height: 24px;" alt="">
          <img v-show="isvisited == '4'" src="../assets/account-fill.png" style="width: 24px;height: 24px;" alt="">
          <div :class="[isvisited == '4' ? 'text-orange' : 'text-black']">My Account</div>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
  import {
    createCoupons,
    getNotice,
    setNotice,
    getId
  } from '@/api/user'
  import uaParser from 'ua-parser-js'
  export default {
    data() {
      return {
        isvisited: '',
        chatShow: false,
        manage: {},
        chatNum: 0,
        istoken: false,
        websocket: '',
        manageNew: {},
        ruleForm: [],
        userID: 0,
        t: null,
        noticeList: [],
        noRead: 0,
        firstLogin: 0,
        times: '',
        email: '',
        head_portrait: '',
        userName: '',
        superLevel: '',
        firstName: '',
        price_status: '0',
        nologinHello: [],
        chatMessage: [],
        entering: false,
        newNotice: null,
        chatPanel: false,
        emojiPanel: false,
        emojiList: [
          '🙂','😁','😂','😊','😍','😐',
          '😕','😒','😢','😭','🎉','❤',
          '👌','👍','🙏','✊','✌','✋',
        ],
        msg: '',
        startPos: 0,
        endPos: 0,
        bookingChat: false,
        bookingName: '',
        portChat: false,
        portName: '',
        orderChat: false,
        orderName: '',
        fileType: 1,
      }
    },
    mounted(){
      document.addEventListener(
        'click',
        this.even,
        true
      )

      if(this.$store.state.user.token){
        this.istoken = true
      }else{
        this.istoken = false
      }
      this.StartWebSocket()
      if(this.istoken){
        let dt = new Date()
        const userId = JSON.parse(localStorage.getItem('userId'))
        const headImg = JSON.parse(localStorage.getItem('userImg'))
        this.head_portrait = headImg + userId + '_40_40.png?v=' + dt.getTime()
        this.times = dt.getTime()
        let user = JSON.parse(localStorage.getItem('userInfo'))
        this.email = user.email
        this.userName = user.first_name + ' ' + user.last_name
        this.userID = user.id
        this.superLevel = user.super_level
        this.firstLogin = user.first_login
        this.firstName = user.first_name
        if(user.hasOwnProperty('manage')){
          this.manage = user.manage
        }
        this.price_status = user.price_status
        // this.themes()
        // console.log(user);
      }else{
        const ua = uaParser(navigator.userAgent)
        // console.log(ua);
        this.ruleForm.browser = ua.browser.name
        this.ruleForm.sys = ua.os.name + ua.os.version
        this.ruleForm.machine = window.screen.width + '*' + window.screen.height
        

        // this.$refs.memberMask.style = 'display:block;'
        // this.$refs.nologinModal.style = 'display:block;'
        // setTimeout(()=>{
        //   this.$refs.memberMask.style = 'display:block;opacity:1;'
        //   this.$refs.nologinModal.style = 'display:block;opacity:1;--tw-scale-x: 1;--tw-scale-y: 1;'
        // })
      }
      this.accessNotice()
    },
    destroyed() {  
      document.removeEventListener('click', this.even, true) 
      clearInterval(this.t)
    },
    methods:{
      even(e){
        if (!this.$refs.emojiPanel.contains(e.target)) {
          this.emojiPanel = false
        }
      },
      async chooseMenu(e) {
        this.isvisited = await e
        if(this.isvisited == '0'){
          this.$refs.mainView.style.height = '100%'
        }else{
          this.$refs.mainView.style.height = 'calc(100% - 50px)'
        }
      },
      async isChat(e){
        this.chatShow = await e
      },
      async isRead(e,n){
        await console.log(e,n);
        this.noRead = Number(this.noRead)-Number(e)
        this.noticeList[n].is_read = 1
        localStorage.setItem("NoticeList", JSON.stringify(this.noticeList))
      },
      async readAll(e){
        let read = await e
        if(read){
          for(let n=0;n<this.noticeList.length;n++){
            this.noticeList[n].is_read = 1
          }
          localStorage.setItem("NoticeList", JSON.stringify(this.noticeList))
        }
      },
      StartWebSocket() {
        this.websocket = new WebSocket("wss://socket.cargosoon.online/wss")
        this.websocket.onopen = this.onOpen;
        this.websocket.onclose = this.onClose;
        this.websocket.onmessage = this.onMessage;
        this.websocket.onerror = this.onError;
      },
      onOpen(e) {
        if(this.istoken){
          var me_1 = {
            type: "init",
            from_user_id: this.userID,
            service_type: 1,
            status: ""
          }
          this.websocket.send(JSON.stringify(me_1))
          this.pingMessage()
        }else{
          this.chatShow = true
          let code = localStorage.getItem('invitation_code')
          getId({
            type: 2,
            code: code
          }).then(res=>{
            this.manageNew = res.data.manage
            localStorage.setItem("manageNew", JSON.stringify(this.manageNew))
            this.ruleForm.id = res.data.chat_id
            this.userID = res.data.chat_id
            this.nologinMessage()
            this.pingMessage()
          })
        }
      },
      pingMessage(){
        let ping = {
          type: "ping",
          from_user_id: this.userID,
          service_type: 1,
        }
        clearInterval(this.t)
        this.t = setInterval(() => {
          if(this.websocket.readyState == 3){
            this.StartWebSocket()
          }
          this.websocket.send(JSON.stringify(ping))
        }, 30000)
      },
      onClose() {
        this.websocket.close();
      },
      onMessage(e) {
        var data = JSON.parse(e.data)
        // console.log(data)
        if(data.type == 'init' || data.type == 'chat_msg_list' || data.type == 'no_login_init'){
          if(data.type == 'init'){
            this.noticeList = data.notice_list
            localStorage.setItem("NoticeList", JSON.stringify(this.noticeList))
            this.noRead = data.no_notice_read
          }
          if(data.type == 'no_login_init'){
            this.manage.id = data.manage_row.id
            this.manage.image_url = data.manage_row.image_url
            this.manage.englishname = data.manage_row.englishname
            this.manage.service_email = data.manage_row.service_email
            this.manage.whatsappp = data.manage_row.whatsappp
            this.chatShow = false
          }
          let list = data.message_list
          // console.log(list)
          if(this.firstLogin == 1 && list.length == 0){
            this.firstSend()
            this.chatNum = 1
          }
          for(let n=0;n<list.length;n++){
            list[n].head_img = list[n].head_img + '?v=' + this.times
            if(list[n].msg_type == 3 || list[n].msg_type == 4){
              list[n].msg = JSON.parse(list[n].msg)
            }
            if(list[n].msg_type == 5){
              this.nologinHello = list[n].msg.split(',')
              // console.log(this.nologinHello);
            }
          }
          // console.log(list)
          this.chatMessage = list
          this.Height()
        }else if(data.type == 'chat_msg'){
          let a = {
            date_entered: data.date_entered,
            head_img: data.head_img + '?v=' + this.times,
            is_me: data.is_me,
            is_read: data.is_read,
            msg: data.msg,
            nickname: data.nickname,
            msg_type: data.msg_type,
          }
          if(data.msg_type == 3 || data.msg_type == 4){
            a.msg = JSON.parse(data.msg)
          }
          if(data.msg_type == 2){
            a.file_name = data.file_name
          }
          this.chatMessage.push(a)
          if(!this.chatShow){
            this.chatNum+=1
          }
          this.Height()
        }else if(data.type == 'chat_msg_entering'){
          this.entering = true
        }else if(data.type == 'chat_msg_cancel_entering'){
          this.entering = false
        }else if(data.type == 'is_read'){
          // this.isReadAll = true
          var message = {
          type: "chat_msg_list",
            from_user_id: this.userID,
            from_service_id: this.manage.id,
            service_type: 1,
          }
          this.websocket.send(JSON.stringify(message))
        }
      },
      onError(){},
      nologinMessage(){
        let code = localStorage.getItem('invitation_code')
        var message = {
          type: "no_login_init",
          sys: this.ruleForm.sys,
          browser: this.ruleForm.browser,
          machine: this.ruleForm.machine,
          from_user_id: this.ruleForm.id,
          code: code,
          manage: this.manageNew
        }
        this.websocket.send(JSON.stringify(message))
      },
      firstSend(){
        var message = {
          type: "first_login",
          from_user_id: this.userID,
          from_service_id: this.manage.id,
          msg_source_type: 'co',
        }
        this.websocket.send(JSON.stringify(message))
      },
      ChatPanelOpen(){
        this.chatPanel = !this.chatPanel
        this.Height()
      },
      Height(){
        this.$nextTick(() => {
          this.$refs.chatPanels.scrollTop = this.$refs.chatPanels.scrollHeight
        })
      },
      accessNotice(){
        this.newNotice = setInterval(() => {
          getNotice().then(res=>{
            if(res.data.msg){
              res.data.is_read = 0
              this.noRead = Number(this.noRead)+1
              this.noticeList.unshift(res.data)
              localStorage.setItem("NoticeList", JSON.stringify(this.noticeList))
            }
            if(res.data.msg == 'You have earned L2 membership benefits'){
              this.superLevel = 'L2'
              let userInfo = JSON.parse(localStorage.getItem('userInfo'))
              userInfo.super_level = 'L2'
              localStorage.setItem('userInfo',JSON.stringify(userInfo))
            }
          })
        }, 60000)
      },
      emojiChoose(e){
        this.insertTxtAndSetcursor(e.target.innerText)
        this.emojiPanel = false
      },
      insertTxtAndSetcursor(emoji){
        let element = this.$refs.textArea
        let oldTxt = this.msg
        let result = oldTxt.substring(0, this.startPos) + emoji + oldTxt.substring(this.endPos)
        this.msg = result
        element.focus()
        this.$nextTick(() => {
          element.selectionStart = this.startPos + emoji.length
          element.selectionEnd = this.startPos + emoji.length
        })
      },
      upload(){
        this.$refs.iptFileRef.click()
      },
      uploads(e){
        const files = e.target.files[0]
        this.formData = new FormData()
        this.formData.append('file', files)
        if(files.type.indexOf('image')!=-1){
          this.fileType = 1
        }else{
          this.fileType = 2
        }
        this.tttt()
      },
      async tttt(){
        let res = await fetch('https://mini.cargosoon.online/api/mini/Login/upload_chat', {
          method: 'POST',
          body: this.formData,
        })
        let data = await res.json()
        if (data.code == "0") {
          // this.isReadAll = false
          var img = data.data.image
          let imgs = 'https://mini.cargosoon.online/api' + img.substr(1)
          if(this.fileType == 1){
            var message = { 
              type: "chat_msg",
              from_user_id: this.userID,
              from_service_id: this.manage.id,
              service_type: 1,
              msg_type: 1,
              // msg: '<img style="max-width:80px;min-height:80px;" src="' + img + '">'
              msg: imgs,
            }
            this.websocket.send(JSON.stringify(message))
          }else{
            var msgs = {
              image: imgs,
              file_name: data.data.file_name,
              file_size: data.data.file_size,
              file_type: data.data.file_type, 
            }
            var message = { 
              type: "chat_msg",
              from_user_id: this.userID,
              from_service_id: this.manage.id,
              service_type: 1,
              msg_type: 2,
              // msg: '<img style="max-width:80px;min-height:80px;" src="' + img + '">'
              msg: JSON.stringify(msgs),
            }
            this.websocket.send(JSON.stringify(message))
          }
        }
      },
      sendEnter(){
        if(this.msg != ''){
          var message = {
            type: "chat_msg_entering",
            from_user_id: this.userID,
            service_type: 1,
          }
          this.websocket.send(JSON.stringify(message))
        }else{
          this.cancelEnter()
        }
      },
      cancelEnter(){
        this.getCursorPosition()
        var message = {
          type: "chat_msg_cancel_entering",
          from_user_id: this.userID,
          service_type: 1,
        }
        this.websocket.send(JSON.stringify(message))
      },
      getCursorPosition(){
        let element = this.$refs.textArea
        this.startPos = element.selectionStart
        this.endPos = element.selectionEnd
      },
      sendMessage(){
        var message = {
          type: "chat_msg",
          from_user_id: this.userID,
          from_service_id: this.manage.id,
          service_type: 1,
          msg_type: 0,
          msg: this.msg,
          msg_source_type: 'co',
        }
        this.websocket.send(JSON.stringify(message))
        this.msg = ''
      },
      listenKey(e){
        this.msg = this.msg + '\n'
      },
      bookingInfo(){
        this.bookingName = JSON.parse(localStorage.getItem('BookItem')).product_name_e
        this.bookingChat = true
        this.ChatPanelOpen()
      },
      sendBooking(){
        let a = JSON.parse(localStorage.getItem('BookItem'))
        let b = JSON.parse(localStorage.getItem('SearchForm'))
        // console.log(a,b);
        if(a.total == 0){
          a.total = 'N'
        }
        let msgs = {
          img: a.product_img,
          name: a.product_name_e,
          date: `${a.aging_start}-${a.aging_end} Business Days`,
          price: `$${a.freight}`,
          weight: `(${a.weight}kg/${a.total}ctns)`,
          destination: b.shipping_to_en
        }
        let controlLog = b
        controlLog.control_id = a.id
        controlLog.all_weight = a.weight
        controlLog.control_name = a.product_name
        controlLog.aging_start = a.aging_start
        controlLog.aging_end = a.aging_end
        controlLog.transport_type = a.transport_type
        controlLog.freight = a.freight
        controlLog.cutomer_id = this.userID

        // console.log(controlLog);
        var message = {
          type: "chat_msg_control",
          from_user_id: this.userID,
          from_service_id: this.manage.id,
          msg: msgs,
          msg_type: 3,
          control_log: controlLog,
        }
        this.websocket.send(JSON.stringify(message))
        this.bookingChat = false
      },
      portInfo(){
        this.portName = JSON.parse(localStorage.getItem('PortItem')).company
        this.portChat = true
        this.ChatPanelOpen()
      },
      sendPort(){
        let a = JSON.parse(localStorage.getItem('PortItem'))
        let b = JSON.parse(localStorage.getItem('PortSearch'))
        let c = {}
        // console.log(a,b);
        if(a.gp20 == 0){
          c.gp20 = 'N'
        }else{
          c.gp20 = '$'+a.gp20
        }
        if(a.gp40 == 0){
          c.gp40 = 'N'
        }else{
          c.gp40 = '$'+a.gp40
        }
        if(a.hq40 == 0){
          c.hq40 = 'N'
        }else{
          c.hq40 = '$'+a.hq40
        }
        if(a.hq45 == 0){
          c.hq45 = 'N'
        }else{
          c.hq45 = '$'+a.hq45
        }
        
        let msgs = {
          img: a.img,
          name: a.company,
          date: a.voyage,
          price: `${c.gp20}/${c.gp40}/${c.hq40}/${c.hq45}`,
          destination: b.end_en
        }
        let controlLog = b
        controlLog.company = a.company
        controlLog.customer_id = this.userID
        controlLog.sea_line = a.sea_line
        controlLog.line = a.line
        controlLog.air_day = a.air_day
        controlLog.validity_start = a.validity_start
        controlLog.validity_end = a.validity_end
        controlLog.gp20 = a.gp20
        controlLog.gp40 = a.gp40
        controlLog.hq40 = a.hq40
        controlLog.hq45 = a.hq45

        var message = {
          type: "chat_msg_control",
          from_user_id: this.userID,
          from_service_id: this.manage.id,
          msg: msgs,
          msg_type: 4,
          control_log: controlLog,
        }
        this.websocket.send(JSON.stringify(message))
        this.portChat = false
      },
      orderInfo(){
        this.orderName = JSON.parse(localStorage.getItem('OrderItem')).enquiry_no
        this.orderChat = true
        this.ChatPanelOpen()
      },
      sendOrder(){
        let controlLog = JSON.parse(localStorage.getItem('OrderItem'))
        let c = {}

        if(controlLog.num == '0'){
          c.num = 'N'
        }else{
          c.num = controlLog.num
        }
        if(controlLog.weight == '0'){
          c.weight = 'N'
        }else{
          c.weight = controlLog.weight
        }
        if(controlLog.volume == '0'){
          c.volume = 'N'
        }else{
          c.volume = controlLog.volume
        }

        let msgs = {
          img: '',
          name: `Order ${controlLog.enquiry_no}`,
          date: '',
          price: '',
          weight: `(${c.num}ctns/${c.weight}kg/${c.volume}cbm)`,
          destination: ''
        }

        var message = {
          type: "chat_msg_control",
          from_user_id: this.userID,
          from_service_id: this.manage.id,
          msg: msgs,
          msg_type: 3,
          control_log: controlLog,
        }
        this.websocket.send(JSON.stringify(message))
        this.orderChat = false
      },
    },
  };
</script>

<style lang="postcss" scoped>
  .mainPanel{
    @apply relative w-full min-h-full;
  }

  .tabbar{
    padding: 6px 0 0;
    width: 100%;
    height: 50px;
    position: fixed;
    bottom: 0;
    left: 0;
    background-color: #fff;
    z-index: 10;
    display: flex;
    user-select: none;
  }

  .barItem{
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 12px;
  }
</style>
